// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".loading-spinner__loadingContainer___3ipLh{position:absolute;width:100%;height:100%;z-index:19;background-color:#888;opacity:.3}.loading-spinner__loadingSpinner___3SWD1{position:absolute;top:calc(50% - 40px);left:calc(50% - 30px);z-index:9999}", "",{"version":3,"sources":["/codebuild/output/src1702457181/src/src/components/core/styles/loading-spinner.scss"],"names":[],"mappings":"AAAA,2CACI,iBAAA,CACA,UAAA,CACA,WAAA,CACA,UAAA,CACA,qBAAA,CACA,UAAA,CAGJ,yCACI,iBAAA,CACA,oBAAA,CACA,qBAAA,CACA,YAAA","file":"loading-spinner.scss","sourcesContent":[".loadingContainer {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    z-index: 19;\n    background-color: #888888;\n    opacity: 0.3;\n}\n\n.loadingSpinner {\n    position: absolute;\n    top: calc(50% - 40px);\n    left: calc(50% - 30px);\n    z-index: 9999;\n}"]}]);
// Exports
exports.locals = {
	"loadingContainer": "loading-spinner__loadingContainer___3ipLh",
	"loadingSpinner": "loading-spinner__loadingSpinner___3SWD1"
};
module.exports = exports;
