import React, { useEffect, useState } from 'react';
import css from './styles/sub-header.scss';
import { useNavigate } from 'react-router-dom';
import { withRouter } from '../../components/core/with-router';
import { useDispatch, useSelector } from 'react-redux';
import Analytics from '../core/analytics';
import classNames from 'classnames';
import FirebaseAnalytics from '../core/firebase-analytics';
import { useTranslation } from 'react-i18next';
import launchJoyride from '../../assets/launch-joyride.svg';
import { useJoyrideContext } from '../../components/joyride/context';
import EulaDialog from '../../components/core/eula-dialog';
import SubHeaderNavLink from './sub-header-nav-link';
import { setSeenJoyride } from '../../redux/actions/appActions';
import InvalidWexCredentialsDialog from '../../components/core/invalid-wex-credentials-dialog';
import FuelManagementService from '../../services/fuel-management.service';
import { setShowInvoicingJoyride } from '../../redux/actions/loadActions';
import { FeatureFlags } from '../../enums/featureFlagEnum';
import NotificationService from '../../services/notification.service';
import NotificationDialog from '../../components/core/notification-dialog';
import { Notification } from '../../interfaces/notification';

const SubHeader = ({ history, prefix, features, proTransLite, header }: any) => {
  const user = useSelector((state: any) => state.app.user);
  const navigate = useNavigate();
  const { setState: setJoyrideState } = useJoyrideContext();
  const [openEula, setOpenEula] = useState(false);
  const [openInvalidWex, setOpenInvalidWex] = useState(false);
  const [notification, setNotification] = useState<Notification | null>();
  const [openNotificationDialog, setOpenNotificationDialog] = useState(false);
  const dispatch = useDispatch();
  const cpua4769 = features.includes('CPUA-4769-WEB');
  const isDispatchUser = features.includes(FeatureFlags.DISPATCH);
  const fuelManagementService = new FuelManagementService();
  const notificationService = new NotificationService();
  const permissions = useSelector((state: any) => state.app.user.permissions);
  const companyAdmin = permissions.find((p: any) => p.name === 'carrierpro.CompanyAdmin' && p.status === 'active');
  const rtscsUser = !!permissions.find((p: any) => p.name === 'fuel.User' && p.status === 'active');
  const invoicePreviewAndResubmission = features.includes(FeatureFlags.INVOICE_PREVIEW_AND_RESUBMISSION);
  const sideNavigationFF = features.includes(FeatureFlags.SIDE_NAVIGATION);
  const wexAutomation = features.includes(FeatureFlags.WEX_INTEGRATION_AUTOMATION);

  useEffect(() => {
    getNotifications();
    determineIfShowJoyRide();
    handleDetermineIfOpenEula();
    proTransLite && companyAdmin && handleShowInvalidWexDialog();
  }, []);

  const getNotifications = async () => {
    if (wexAutomation) {
      try {
        const notifications = await notificationService.getNotifications();
        if (notifications?.length > 0) {
          const filteredNotifications = notifications.filter(
            notification => notification.archivedOn === null && notification.status === 'Unread',
          );
          const mostRecentNotification = filteredNotifications.sort((a, b) => b.createdOn - a.createdOn)[0];

          if (mostRecentNotification) {
            setNotification(mostRecentNotification);
            setOpenNotificationDialog(true);
          }
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  const determineIfShowJoyRide = (eulaAccepted: boolean = false) => {
    if (
      proTransLite &&
      ((user?.hasOwnProperty('eulaAccepted') && user.eulaAccepted === true) || eulaAccepted) &&
      !user?.metaData?.seenJoyrides?.tms
    )
      setJoyrideState({ tourActive: true, run: true, joyrideId: 'tms' });
  };

  const handleLoadsClicked = () => {
    FirebaseAnalytics.sendEvent(
      FirebaseAnalytics.EVENTS.CLICK,
      FirebaseAnalytics.MODULES.LOADS,
      FirebaseAnalytics.PAGES.SUB_HEADER,
      { label: FirebaseAnalytics.LABELS.LOADS },
    );
    navigate(`/loads`);
  };

  const handleAgingClicked = () => {
    Analytics.sendEvent(Analytics.CATEGORIES.SUB_HEADER, Analytics.ACTIONS.CLICKED, Analytics.LABELS.AGING);
    FirebaseAnalytics.sendEvent(
      FirebaseAnalytics.EVENTS.CLICK,
      FirebaseAnalytics.MODULES.LOADS,
      FirebaseAnalytics.PAGES.SUB_HEADER,
      { label: FirebaseAnalytics.LABELS.AGING },
    );
    navigate(`${prefix || ''}/reports/aging`);
  };

  const handleIFTAClicked = () => {
    FirebaseAnalytics.sendEvent(
      FirebaseAnalytics.EVENTS.CLICK,
      FirebaseAnalytics.MODULES.LOADS,
      FirebaseAnalytics.PAGES.SUB_HEADER,
      { label: FirebaseAnalytics.LABELS.IFTA },
    );
    navigate(`${prefix || ''}/ifta`);
  };

  const handleFuelClicked = () => {
    FirebaseAnalytics.sendEvent(
      FirebaseAnalytics.EVENTS.CLICK,
      FirebaseAnalytics.MODULES.LOADS,
      FirebaseAnalytics.PAGES.SUB_HEADER,
      { label: FirebaseAnalytics.LABELS.FUEL },
    );
    navigate(`${prefix || ''}/fuel`);
  };

  const handleMyFleetClicked = () => {
    FirebaseAnalytics.sendEvent(
      FirebaseAnalytics.EVENTS.CLICK,
      FirebaseAnalytics.MODULES.LOADS,
      FirebaseAnalytics.PAGES.SUB_HEADER,
      { label: FirebaseAnalytics.LABELS.MY_FLEET },
    );
    navigate(`${prefix || ''}/myfleet`);
  };

  const handleCompanyInfoClicked = () => {
    FirebaseAnalytics.sendEvent(
      FirebaseAnalytics.EVENTS.CLICK,
      FirebaseAnalytics.MODULES.LOADS,
      FirebaseAnalytics.PAGES.SUB_HEADER,
      { label: FirebaseAnalytics.LABELS.COMPANY_INFO },
    );
    navigate(`${prefix || ''}/company`);
  };

  const handleStatementsClicked = () => {
    FirebaseAnalytics.sendEvent(
      FirebaseAnalytics.EVENTS.CLICK,
      FirebaseAnalytics.MODULES.LOADS,
      FirebaseAnalytics.PAGES.SUB_HEADER,
      { label: FirebaseAnalytics.LABELS.STATEMENTS },
    );
    navigate(`${prefix || ''}/statements`);
  };

  const handleDispatchClicked = () => {
    FirebaseAnalytics.sendEvent(
      FirebaseAnalytics.EVENTS.CLICK,
      FirebaseAnalytics.MODULES.DISPATCH,
      FirebaseAnalytics.PAGES.SUB_HEADER,
      { label: FirebaseAnalytics.LABELS.DISPATCH },
    );
    navigate(`${prefix || ''}/dispatch`);
  };

  const handleJoyrideStartAnalyticsClick = () => {
    FirebaseAnalytics.sendEvent(
      FirebaseAnalytics.EVENTS.CLICK,
      FirebaseAnalytics.MODULES.LOAD_MANAGEMENT,
      FirebaseAnalytics.PAGES.SUB_HEADER,
      {
        label: FirebaseAnalytics.LABELS.JOYRIDE_VIEW,
      },
    );
  };

  const handleDashboardClicked = () => {
    FirebaseAnalytics.sendEvent(
      FirebaseAnalytics.EVENTS.CLICK,
      FirebaseAnalytics.MODULES.LOADS,
      FirebaseAnalytics.PAGES.SUB_HEADER,
      { label: FirebaseAnalytics.LABELS.DASHBOARD },
    );
    navigate(`${prefix || ''}/dashboard`);
  };

  const { t } = useTranslation();

  const showJoyride = () => {
    handleJoyrideStartAnalyticsClick();
    // NOTE: First step in invoicing joyride is actually a modal not a step
    if (location.pathname === '/loads' && invoicePreviewAndResubmission) {
      dispatch(setShowInvoicingJoyride(true));
    } else {
      setJoyrideState({ tourActive: true, run: true, joyrideId: determinateJoyrideId(), manualRunActive: true });
    }
  };

  const determinateJoyrideId = () => {
    if (location.pathname.includes('reports/aging')) return 'agingReport';
    else if (location.pathname.includes('/fuel')) return 'fuelEntry';

    return 'tms';
  };

  const handleShowInvalidWexDialog = async () => {
    const integrationStatus = await fuelManagementService.getIntegrationStatus('wex');
    if (integrationStatus.actionRequired && !rtscsUser) {
      setOpenInvalidWex(true);
    }
  };

  const handleDetermineIfOpenEula = () => {
    if (user && (!user.hasOwnProperty('eulaAccepted') || user.eulaAccepted === false)) {
      setOpenEula(true);
    }
  };

  const handleEulaAccepted = () => {
    setOpenEula(false);
    determineIfShowJoyRide(true);
  };

  const displayJoyrideButton = () => {
    const conditionForBasic =
      (location.pathname.includes('/statements') ||
        location.pathname.includes('/reports/aging') ||
        location.pathname.includes('/ifta') ||
        location.pathname.includes('/company')) &&
      !proTransLite;
    return (proTransLite || cpua4769) && !conditionForBasic;
  };

  return (
    <>
      <EulaDialog open={openEula} handleEulaAccepted={handleEulaAccepted} user={user} />
      <InvalidWexCredentialsDialog isOpen={openInvalidWex} onClose={() => setOpenInvalidWex(false)} />
      {wexAutomation && notification && (
        <NotificationDialog
          notification={notification}
          open={openNotificationDialog}
          onClose={() => setOpenNotificationDialog(!openNotificationDialog)}
          onNavigate={navigate}
        />
      )}
      <div className={classNames(css.noPrint)}>
        <div className={css.subNavHeaderWrapper}>
          <div className={css.navItems}>
            <SubHeaderNavLink
              end
              to={`${prefix || ''}/dashboard`}
              onClick={handleDashboardClicked}
              data-joyrideid="dashboard"
              data-testid="dashboard-header"
              includeLink={proTransLite}
            >
              {t('dashboard')}
            </SubHeaderNavLink>

            <SubHeaderNavLink
              end
              to={'/loads'}
              onClick={handleLoadsClicked}
              data-joyrideid="loads"
              data-testid="loads-header"
            >
              {t('Loads')}
            </SubHeaderNavLink>

            {isDispatchUser && (
              <SubHeaderNavLink
                to={`${prefix || ''}/dispatch`}
                onClick={handleDispatchClicked}
                data-joyrideid="dispatch"
                data-testid="dispatch-header"
              >
                {t('dispatch')}
              </SubHeaderNavLink>
            )}

            <SubHeaderNavLink
              to={`${prefix || ''}/statements`}
              onClick={handleStatementsClicked}
              data-joyrideid="statements"
              data-testid="statements-header"
            >
              {t('statements')}
            </SubHeaderNavLink>

            <SubHeaderNavLink
              to={`${prefix || ''}/reports/aging`}
              onClick={handleAgingClicked}
              data-testid="reports-header"
              data-joyrideid="aging"
            >
              {t('aging_report')}
            </SubHeaderNavLink>

            <SubHeaderNavLink
              to={`${prefix || ''}/fuel`}
              onClick={handleFuelClicked}
              data-joyrideid="fuel"
              data-testid="fuel-header"
            >
              {t('Fuel Entry')}
            </SubHeaderNavLink>

            {!user.userStatus.isTrial && (
              <SubHeaderNavLink to={`${prefix || ''}/ifta`} onClick={handleIFTAClicked} data-testid="ifta-header">
                {t('IFTA Report')}
              </SubHeaderNavLink>
            )}

            <SubHeaderNavLink
              to={`${prefix || ''}/myfleet`}
              onClick={handleMyFleetClicked}
              data-joyrideid="myfleet"
              data-testid="myfleet-header"
            >
              {t('My Fleet')}
            </SubHeaderNavLink>

            <SubHeaderNavLink
              to={`${prefix || ''}/company`}
              onClick={handleCompanyInfoClicked}
              data-joyrideid="company"
              data-testid="company-header"
            >
              {t('Company Info')}
            </SubHeaderNavLink>
            <div className={css.sideControls}>
              {displayJoyrideButton() && (
                <button
                  className={css.joyride}
                  style={{ marginRight: sideNavigationFF ? 15 : 80 }}
                  onClick={proTransLite ? showJoyride : () => dispatch(setSeenJoyride('ptBasic', false))}
                >
                  <img src={launchJoyride} alt={t('launch_tour')} />
                </button>
              )}
              {sideNavigationFF && <div className={css.controls}>{header}</div>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(SubHeader);
