// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".snackbar__error___1m5lZ div{background-color:#d32f2f !important;font-size:15px;font-weight:800}.snackbar__error___1m5lZ div svg{color:#fff}#snackbar__messageId___3mya9{font-size:15px;font-weight:800}.snackbar__snackbarButton___3A1i6{font-weight:900 !important;color:#adff2f !important}.snackbar__white___3ZNKX{color:#fff !important}", "",{"version":3,"sources":["/codebuild/output/src1702457181/src/src/components/core/styles/snackbar.scss"],"names":[],"mappings":"AAAA,6BACE,mCAAA,CACA,cAAA,CACA,eAAA,CAEA,iCACE,UAAA,CAIJ,6BACE,cAAA,CACA,eAAA,CAGF,kCACE,0BAAA,CACA,wBAAA,CAGF,yBACE,qBAAA","file":"snackbar.scss","sourcesContent":[".error div {\n  background-color: rgb(211, 47, 47) !important;\n  font-size: 15px;\n  font-weight: 800;\n\n  svg {\n    color: white;\n  }\n}\n\n#messageId {\n  font-size: 15px;\n  font-weight: 800;\n}\n\n.snackbarButton {\n  font-weight: 900 !important;\n  color: greenyellow !important;\n}\n\n.white {\n  color: white !important;\n}\n"]}]);
// Exports
exports.locals = {
	"error": "snackbar__error___1m5lZ",
	"messageId": "snackbar__messageId___3mya9",
	"snackbarButton": "snackbar__snackbarButton___3A1i6",
	"white": "snackbar__white___3ZNKX"
};
module.exports = exports;
